
export const ks8223 = {
    "file": "ks8223",
    "data": [
        {
            "label": "Processor ",
            "desc": [
                "Quad-Core @1.1GHz",
                "32-bit security processor"
            ],
            "pic": "cpu.png"
        },
        {
            "label": "Memory",
            "desc": [
                "1GB RAM+8GB ROM",
                "2GB RAM+16GB ROM(optional)",
                "MicroSD.1 Support up to 32GB"
            ],
            "pic": "ram.png"
        },
        {
            "label": "Os",
            "desc": [
                "Android 5.1 / 7.1",
                "zOS based on Android 7.1"
            ],
            "pic": "sys.png"
        },
        {
            "label": "Algorithm",
            "desc": [
                "3DES、AES、RSA、SHA-256、SM2、SM3、SM4、etc"
            ],
            "pic": "safe.png"
        },
        {
            "label": "Display",
            "desc": [
                "5“ 720x1280 Backight control Multi-touch opuration"
            ],
            "pic": "disp.png"
        },
        {
            "label": "Card Reader",
            "desc": [
                "Magneticx1.Contact ICx1",
                "Contactless ICx1,PSAMx1",
                "MicroSD×1"
            ],
            "pic": "read.png"
        },
        {
            "label": "Printer",
            "desc": [
                "Built-in 58mm therrmal printer",
                "40mm poper roll"
            ],
            "pic": "prt.png"
        },
        {
            "label": "Camera",
            "desc": [
                "Rear:5 mega plxels flash autofocus",
                "Font:2 mega plxels",
                "Support 1D/2D"
            ],
            "pic": "face.png"
        },
        {
            "label": "Cellular Network",
            "desc": [
                "Dual sim slot (optional) GSM/ WCDMA /EVDO /TD-SCDMA/TDD-LTE/FDD-LTE"
            ],
            "pic": "mobi.png"
        },
        {
            "label": "WIFI",
            "desc": [
                "IEEE 80211 b/g/n 2.4GHz"
            ],
            "pic": "wifi.png"
        },
        {
            "label": "Bluetooth",
            "desc": [
                "Bluetooth 4.0 and above BLE"
            ],
            "pic": "blt.png"
        },
        {
            "label": "Location",
            "desc": [
                "Built in GPS / Beidou / GLONASS",
                "Support LBS function",
                "Support AGPS"
            ],
            "pic": "lbs.png"
        },
        {
            "label": "Biometric (optional)",
            "desc": [
                "Fingerprint、Iris"
            ],
            "pic": "act.png"
        },
        {
            "label": "Speaker",
            "desc": [
                "Built-in speaker,O.5 W",
                "82dB+-3dB"
            ],
            "pic": "speaker.png"
        },
        {
            "label": "Port",
            "desc": [
                "Micro USB×1"
            ],
            "pic": "port.png"
        },
        {
            "label": "Keypad",
            "desc": [
                "Virtual pinpad"
            ],
            "pic": "keyb.png"
        },
        {
            "label": "Button",
            "desc": [
                "Powerx1",
                "Volume x2"
            ],
            "pic": "keyt.png"
        },
        {
            "label": "Battery",
            "desc": [
                "LiPo battery,3.7V/5200mAh",
                "Optionai wide temperature battery"
            ],
            "pic": "battery.png"
        },
        {
            "label": "Adapter",
            "desc": [
                "lnput AC 100~240V",
                "Output: DC 5V/2A"
            ],
            "pic": "char.png"
        },
        {
            "label": "Dimension(LxWxH)",
            "desc": [
                "220mm×90mm×56mm"
            ],
            "pic": "size.png"
        },
        {
            "label": "Environment",
            "desc": [
                "Working temp:0ºC~+50ºC  Storage temp:-20 ºC +70 ºC  Reletive humidlity :10%~90%RH(non-condensing)"
            ],
            "pic": "env.png"
        }
    ]
}
export const ks8226 = {
    "file": "ks8226",
    "data": [
        {
            "label": "Processor ",
            "desc": [
                "Quad-Core@1IGHz",
                "32-bit security processor"
            ],
            "pic": "cpu.png"
        },
        {
            "label": "Memory",
            "desc": [
                "1GB RAM+8GB ROM",
                "2GB RAM+16GB ROM(optional)",
                "MicroSD.1 Support up to 32GB"
            ],
            "pic": "ram.png"
        },
        {
            "label": "Os",
            "desc": [
                "Android 7.1",
                "zOS based on Android 7.1"
            ],
            "pic": "sys.png"
        },
        {
            "label": "Algorithm",
            "desc": [
                "3DES、AES、RSA、SHA-256、SM2、SM3、SM4、etc"
            ],
            "pic": "safe.png"
        },
        {
            "label": "Display",
            "desc": [
                "5“ 720x1280 Backight control Multi-touch opuration"
            ],
            "pic": "disp.png"
        },
        {
            "label": "Card Reader",
            "desc": [
                "Magneticx1.Contact ICx1",
                "Contactless ICx1,PSAMx1",
                "MicroSD×1"
            ],
            "pic": "read.png"
        },
        {
            "label": "Printer",
            "desc": [
                "Built-in 58mm therrmal printer",
                "40mm poper roll"
            ],
            "pic": "prt.png"
        },
        {
            "label": "Camera",
            "desc": [
                "Rear:5 mega plxels flash autofocus",
                "Font:2 mega plxels",
                "Support 1D/2D"
            ],
            "pic": "face.png"
        },
        {
            "label": "Cellular Network",
            "desc": [
                "Dual sim slot (optional) GSM/ WCDMA /EVDO /TD-SCDMA/TDD-LTE/FDD-LTE"
            ],
            "pic": "mobi.png"
        },
        {
            "label": "WIFI",
            "desc": [
                "IEEE 80211 b/g/n 2.4GHz"
            ],
            "pic": "wifi.png"
        },
        {
            "label": "Bluetooth",
            "desc": [
                "Bluetooth 4.0 and above BLE"
            ],
            "pic": "blt.png"
        },
        {
            "label": "Location",
            "desc": [
                "Built in GPS / Beidou / GLONASS",
                "Support LBS function",
                "Support AGPS"
            ],
            "pic": "lbs.png"
        },
        {
            "label": "Biometric (optional)",
            "desc": [
                "Fingerprint、Iris"
            ],
            "pic": "act.png"
        },
        {
            "label": "Speaker",
            "desc": [
                "Built-in speaker,O.5 W",
                "82dB+-3dB"
            ],
            "pic": "speaker.png"
        },
        {
            "label": "Port",
            "desc": [
                "USBType-C×1"
            ],
            "pic": "port.png"
        },
        {
            "label": "Keypad",
            "desc": [
                "Virtual pinpad"
            ],
            "pic": "keyb.png"
        },
        {
            "label": "Button",
            "desc": [
                "Powerx1",
                "Volume x2"
            ],
            "pic": "keyt.png"
        },
        {
            "label": "Battery",
            "desc": [
                "LiPo battery,3.7V/5200mAh",
                "Optionai wide temperature battery"
            ],
            "pic": "battery.png"
        },
        {
            "label": "Adapter",
            "desc": [
                "lnput AC 100~240V",
                "Output: DC 5V/2A"
            ],
            "pic": "char.png"
        },
        {
            "label": "Dimension(LxWxH)",
            "desc": [
                "190mm×82mm×59mm"
            ],
            "pic": "size.png"
        },
        {
            "label": "Environment",
            "desc": [
                "Working temp:0ºC~+50ºC  Storage temp:-20 ºC +70 ºC  Reletive humidlity :10%~90%RH(non-condensing)"
            ],
            "pic": "env.png"
        }
    ]
}
export const ks8123 = {
    "file": "ks8123",
    "data": [
        {
            "label": "Processor ",
            "desc": [
                "Octa-core@2.0GHz(Pro Version)",
                "Quad-core@1.1GHz(Standard Version)"
            ],
            "pic": "cpu.png"
        },
        {
            "label": "Memory",
            "desc": [
                "4GB RAM + 64GB ROM",
                "2GB RAM + 16GB ROM"
            ],
            "pic": "ram.png"
        },
        {
            "label": "Os",
            "desc": [
                "Android 9.0",
                "Android 7.1"
            ],
            "pic": "sys.png"
        },
        {
            "label": "Algorithm",
            "desc": [
                "3DES、AES、RSA、SHA-256、SM2、SM3、SM4、etc"
            ],
            "pic": "safe.png"
        },
        {
            "label": "Display",
            "desc": [
                "10.1“ 1280x800 Adjustable backlight",
                "Multi-touch capacitive touch screen"
            ],
            "pic": "disp.png"
        },
        {
            "label": "Camera(optional)",
            "desc": [
                "1.3 mega-pixel optical camera",
                "3D structure light(facial recognition)"
            ],
            "pic": "face.png"
        },
        {
            "label": "ID (optional)",
            "desc": [
                "ID",
                "Fingerprint"
            ],
            "pic": "id.png"
        },
        {
            "label": "Scanner",
            "desc": [
                "QR Code / EAN-13 / UPC-A / UPC-E / EAN-8 / Code 128 / Code39 / Interleaved 2of5"
            ],
            "pic": "scan.png"
        },
        {
            "label": "Ethernet",
            "desc": [
                "10/100Mbps"
            ],
            "pic": "ethr.png"
        },
        {
            "label": "Cellular Network",
            "desc": [
                " GSM / WCDMA / EVDO / TD-SCDMA /  TDD-LTE / FDD-LTE"
            ],
            "pic": "mobi.png"
        },
        {
            "label": "Bluetooth",
            "desc": [
                "Bluetooth 4.0 and above BLE"
            ],
            "pic": "blt.png"
        },
        {
            "label": "WIFI",
            "desc": [
                "IEEE、802.11 a/b/g/n/ac",
                "2.4G Hz + 5G Hz"
            ],
            "pic": "wifi.png"
        },
        {
            "label": "Location",
            "desc": [
                "Built in GPS / Beidou / GLONASS",
                "Support LBS function",
                "Support AGPS"
            ],
            "pic": "lbs.png"
        },
        {
            "label": "Printer",
            "desc": [
                "Built-in 58mm thermal printer",
                "40mm poper roll"
            ],
            "pic": "prt.png"
        },
        {
            "label": "Keypad",
            "desc": [
                "Powerx1",
                "Built-in Pinpad",
                "External Pinpad(optional)"
            ],
            "pic": "keyb.png"
        },
        {
            "label": "Speaker",
            "desc": [
                "Built-in speaker,2W"
            ],
            "pic": "speaker.png"
        },
        {
            "label": "Port",
            "desc": [
                "USBx2、MicroUSBx1、RS232x1",
                "RJ45x1、SIMx1、HDMIx1"
            ],
            "pic": "port.png"
        },
        {
            "label": "Adapter",
            "desc": [
                "lnput AC 100~240V",
                "Output:DC 12V/2A"
            ],
            "pic": "char.png"
        },
        {
            "label": "Dimension(LxWxH)",
            "desc": [
                "320mm×260mm×60mm"
            ],
            "pic": "size.png"
        },
        {
            "label": "Environment",
            "desc": [
                "Working temp:0ºC~+50ºC  Storage temp:-20 ºC +70 ºC  Reletive humidlity :10%~90%RH(non-condensing)"
            ],
            "pic": "env.png"
        },
        {
            "label": "Keypad",
            "desc": [
                "Powerx1",
                "Built-in Pinpad",
                "External Pinpad(optional)"
            ],
            "pic": "keyb.png"
        }
    ]
}
export const zt8623 = {
    "file": "zt8623",
    "data": [
        {
            "label": "Processor",
            "desc": [
                "Quad core Cortex-A73@2.0Ghz",
                "Quad core Cortex-A73@2.0Ghz"
            ],
            "pic": "cpu.png"
        },
        {
            "label": "Memory",
            "desc": [
                "2GB RAM+16GB ROM"
            ],
            "pic": "ram.png"
        },
        {
            "label": "Os",
            "desc": [
                "ZOS based on Android 9.0"
            ],
            "pic": "sys.png"
        },
        {
            "label": "Display",
            "desc": [
                "10.1\"800×1280",
                "With backlight control"
            ],
            "pic": "disp.png"
        },
        {
            "label": "Touch Screen",
            "desc": [
                "Capacitive touch screen",
                "Handwriting input"
            ],
            "pic": "touch.png"
        },
        {
            "label": "Scanner(optional)",
            "desc": [
                "QR/ EAN-13/ UPC-A/ UPC-E/EAN-8 / Code 128/ Code39 / lnterleaved 2of5"
            ],
            "pic": "scan.png"
        },
        {
            "label": "Contactless(optional)",
            "desc": [
                "ISO14443 TypeA/B",
                "EMV4.3",
                "EMV4.3"
            ],
            "pic": "utic.png"
        },
        {
            "label": "Encrypted Pinpad",
            "desc": [
                "Financial encrypted pinpad",
                "Contactless optional"
            ],
            "pic": "keyb.png"
        },
        {
            "label": "Ethernet",
            "desc": [
                "10/100/1000Mbps"
            ],
            "pic": "ethr.png"
        },
        {
            "label": "Cellular Network",
            "desc": [
                "GSM / WCDMA/ EVDO/TD-SCDMA/TDD-LTE/FDD-LTE"
            ],
            "pic": "mobi.png"
        },
        {
            "label": "WIFI",
            "desc": [
                "IEEE 802.11 a/ blg/n/ ac",
                "2.4G Hz＋5G Hz"
            ],
            "pic": "wifi.png"
        },
        {
            "label": "Bluetooth",
            "desc": [
                "Bluetooth 4.0 and above",
                "BLE"
            ],
            "pic": "blt.png"
        },
        {
            "label": "Location",
            "desc": [
                "Built-in GPS/Beidou/Glonass",
                "LBS",
                "AGPS"
            ],
            "pic": "lbs.png"
        },
        {
            "label": "Keypad(optional)",
            "desc": [
                "Physical keys x 12",
                "Display x 1"
            ],
            "pic": "mkeyb.png"
        },
        {
            "label": "Port",
            "desc": [
                "USBx2、MicroUSBx1、RS232×1",
                "RJ45×1、SIM×1、Powerx1"
            ],
            "pic": "port.png"
        },
        {
            "label": "Adapter",
            "desc": [
                "lnput: AC 100-240V",
                "Output: DC12V/2A"
            ],
            "pic": "char.png"
        },
        {
            "label": "Bracket",
            "desc": [
                "Metal lifting bracket (Rotatable)"
            ],
            "pic": "brk.png"
        },
        {
            "label": "Environment",
            "desc": [
                "Working temp: 0℃~+50℃",
                "Storage temp: -20℃~+70℃",
                "Relative humidity : 10%~90%RH(non-condensing)"
            ],
            "pic": "env.png"
        }
    ]
}
export const zt8305 = {
    "file": "zt8305",
    "data": [
        {
            "label": "Processor",
            "desc": [
                "Quad-oore@ 1.1GHz",
                "32-bitsea rityprooessor"
            ],
            "pic": "cpu.png"
        },
        {
            "label": "Memory",
            "desc": [
                "1GB RAM+8GB ROM",
                "2GB RAM+ 16GB ROM (optional)",
                "Micros SD x1, suppot up to 32GB"
            ],
            "pic": "ram.png"
        },
        {
            "label": "OS",
            "desc": [
                "ZOS Based on Android 5.1/7.1"
            ],
            "pic": "sys.png"
        },
        {
            "label": "Algorithm",
            "desc": [
                "3DES, AES, RSA, SHA-256.",
                "SM2, SM3, SM4, etc."
            ],
            "pic": "safe.png"
        },
        {
            "label": "Display",
            "desc": [
                "5.7\" 640x480",
                "Explosion- proof capacitive touch screen"
            ],
            "pic": "disp.png"
        },
        {
            "label": "Card Reader (optional)",
            "desc": [
                "Magnetic stripe x1、contact IC x1",
                "Contactless IC x1、PSAM x2"
            ],
            "pic": "read.png"
        },
        {
            "label": "Cellular Network",
            "desc": [
                "GSM/ WCDMA/ EVDO/TD-SCDMA/ TDD-LTE/FDD-LTE"
            ],
            "pic": "mobi.png"
        },
        {
            "label": "WIFI",
            "desc": [
                "IEEE 802.11 b/g/n",
                "2.4GHz"
            ],
            "pic": "wifi.png"
        },
        {
            "label": "Bluetooth",
            "desc": [
                "Bluetooth 4.0 and above",
                "BLE"
            ],
            "pic": "blt.png"
        },
        {
            "label": "Location",
            "desc": [
                "Built-in GPS/Beidou/Glonass",
                "LBS",
                "AGPS"
            ],
            "pic": "lbs.png"
        },
        {
            "label": "Audio",
            "desc": [
                "Digital microphones and speakers interface(3.5mm )"
            ],
            "pic": "vf.png"
        },
        {
            "label": "Sealing",
            "desc": [
                "IP65"
            ],
            "pic": "guard.png"
        },
        {
            "label": "Input",
            "desc": [
                "DC12V/5A"
            ],
            "pic": "char.png"
        },
        {
            "label": "Port",
            "desc": [
                "USBx1、Micro USBx 1、RS232x4",
                "RJ45x1、Powerx1、4G ANTx 1",
                "WiFiANTx 1"
            ],
            "pic": "port.png"
        },
        {
            "label": "Dimension(LxWxH)",
            "desc": [
                "162mmx 141mm x 37.7mm"
            ],
            "pic": "size.png"
        },
        {
            "label": "Environment",
            "desc": [
                "Working temp: -20°C ~+70°C",
                "Storage temp: -40C~+85C",
                "Relative humidity: 10%~90%RH (non-condensing)"
            ],
            "pic": "env.png"
        }
    ]
}
export const zt8629 = {
    "file": "zt8629",
    "data": [
        {
            "label": "Processor",
            "desc": [
                "Quad core Cortex-A73@2.0Ghz",
                "Quad core Cortex-A73@2.0Ghz"
            ],
            "pic": "cpu.png"
        },
        {
            "label": "Memory",
            "desc": [
                "2GB RAM + 16GB ROM",
                "3GB RAM+32GB ROM( optional)"
            ],
            "pic": "ram.png"
        },
        {
            "label": "Os",
            "desc": [
                "ZOS based on Android 9.0"
            ],
            "pic": "sys.png"
        },
        {
            "label": "Algorithm",
            "desc": [
                "3DES、AES、RSA、SHA-256、SM2、SM3、SM4 etc."
            ],
            "pic": "safe.png"
        },
        {
            "label": "Display",
            "desc": [
                "Main display:15.6\" 1920 ×1080",
                "Customer display : 10.1\" 800×1280",
                "Dual screen, asynchronous display"
            ],
            "pic": "disp.png"
        },
        {
            "label": "Card Reader",
            "desc": [
                "Contactless x1, MicroSD x1",
                "Contact x 2 (optional)",
                "PSAM x 1(optional)"
            ],
            "pic": "read.png"
        },
        {
            "label": "Printer",
            "desc": [
                "Built-in 80mm thermal printer",
                "Automatic paper cutting",
                "58mm(optional)"
            ],
            "pic": "prt.png"
        },
        {
            "label": "Camera (optional)",
            "desc": [
                "3D Structure Light/ lnfrared binocular camera optional",
                "Live body detection, QR scanning"
            ],
            "pic": "face.png"
        },
        {
            "label": "lD(optional)",
            "desc": [
                "Frequency:13.56Mhz±7Khz"
            ],
            "pic": "2id.png"
        },
        {
            "label": "Scanner",
            "desc": [
                "QR Code / EAN-13/ UPC-A/ UPC-E / EAN-8 / Code 128/ Code39 / Interleaved 2of5"
            ],
            "pic": "scan.png"
        },
        {
            "label": "Ethernet",
            "desc": [
                "10/100Mbps"
            ],
            "pic": "ethr.png"
        },
        {
            "label": "Cellular Network",
            "desc": [
                "GSM / WCDMA / EVDO / TD-SCDMA /  TDD-LTE / FDD-LTE"
            ],
            "pic": "mobi.png"
        },
        {
            "label": "WIFI",
            "desc": [
                "IEEE 802.11 a / b / g / n / ac",
                "2.4G Hz + 5G Hz"
            ],
            "pic": "wifi.png"
        },
        {
            "label": "Bluetooth",
            "desc": [
                "Bluetooth 2.1/3.0/4.2",
                "BLE"
            ],
            "pic": "blt.png"
        },
        {
            "label": "Location",
            "desc": [
                "Built-in GPS/Beidou/Glonass",
                "LBS",
                "AGPS"
            ],
            "pic": "lbs.png"
        },
        {
            "label": "Keypad",
            "desc": [
                "Physical keys x 12",
                "Display x 1"
            ],
            "pic": "keyb.png"
        },
        {
            "label": "Port",
            "desc": [
                "USB×4、RS232×1、RJ11×1",
                "RJ45×1、Power×1"
            ],
            "pic": "port.png"
        },
        {
            "label": "Adapter",
            "desc": [
                "Input: AC 100-240V",
                "Output: DC 24V/2.5A"
            ],
            "pic": "char.png"
        },
        {
            "label": "Dimension(LxW×H)",
            "desc": [
                "295mm×384mm×398mm"
            ],
            "pic": "size.png"
        },
        {
            "label": "Environment",
            "desc": [
                "Working temp: 0℃~+50℃",
                "Storage temp: -20℃~+70℃",
                "Relative humidity : 10%~90%RH(non-condensing)"
            ],
            "pic": "env.png"
        }
    ]
}
export const zt8228 = {
    "file": "zt8228",
    "data": [
        {
            "label": "Processor",
            "desc": [
                "Quad-core",
                "Cortex- A53@1.5Ghz"
            ],
            "pic": "cpu.png"
        },
        {
            "label": "Memory",
            "desc": [
                "1GB RAM +8GB ROM",
                "2GB RAM +16GB ROM (optional)",
                "support up to3GB RAM +32GB ROM"
            ],
            "pic": "ram.png"
        },
        {
            "label": "OS",
            "desc": [
                "ZOS based on Android 11"
            ],
            "pic": "sys.png"
        },
        {
            "label": "Display",
            "desc": [
                "6\" water-drop screen",
                "1520 x 720TFT LCD"
            ],
            "pic": "disp.png"
        },
        {
            "label": "Camera",
            "desc": [
                "Rear: 5 M pixels",
                "Rear: 8 M pixels, flash Up to 30 frames/sec"
            ],
            "pic": "face.png"
        },
        {
            "label": "Touch screen",
            "desc": [
                "Multi-touch capacitive screen"
            ],
            "pic": "touch.png"
        },
        {
            "label": "Security",
            "desc": [
                "3DES、AES、RSA、SHA-256、SM2、SM3、SM4，etc."
            ],
            "pic": "safe.png"
        },
        {
            "label": "Cellular Network",
            "desc": [
                "GSM/ WCDMA/EVDO/TD-SCDMA/ TDD-LTE/FDD-LTE"
            ],
            "pic": "mobi.png"
        },
        {
            "label": "WIFI",
            "desc": [
                "IEEE 802.11b/g/n",
                "2.4GHz"
            ],
            "pic": "wifi.png"
        },
        {
            "label": "Bluetooth",
            "desc": [
                "Bluetooth 4.1",
                "BLE"
            ],
            "pic": "blt.png"
        },
        {
            "label": "Location",
            "desc": [
                "Built-in GPS/Beidou/Glonass",
                "LBS",
                "AGPS"
            ],
            "pic": "lbs.png"
        },
        {
            "label": "Codes type",
            "desc": [
                "QR Code ,EAN- 13/UPC-A,UPC-E,EAN-8，Code 128,Code39，Interleaved 2of5"
            ],
            "pic": "scan.png"
        },
        {
            "label": "Card Reader",
            "desc": [
                "Magneticx1、ContactIC x1",
                "Contactless IC x1"
            ],
            "pic": "read.png"
        },
        {
            "label": "Printer",
            "desc": [
                "58mm thermal printer(Standard)",
                "Printing speed:80mm/s,40mm paper roll",
                "80mm paper roll support(optional)"
            ],
            "pic": "prt.png"
        },
        {
            "label": "Fingerprint (optional)",
            "desc": [
                "Optical, capacitive support",
                "FBI complaint support",
                "India STQC complaint support"
            ],
            "pic": "fgip.png"
        },
        {
            "label": "Fiscal box ( optional)",
            "desc": [
                "Support self-destruction",
                "Whendisassembly",
                "Secure storage 128mb"
            ],
            "pic": "tax1.png"
        },
        {
            "label": "Keypad (optional)",
            "desc": [
                "Encrypted Pinpad, PCI certified"
            ],
            "pic": "keyb.png"
        },
        {
            "label": "Speaker",
            "desc": [
                "Built-in speaker, 2W"
            ],
            "pic": "speaker.png"
        },
        {
            "label": "Port",
            "desc": [
                "TYPE-C USB×1"
            ],
            "pic": "port.png"
        },
        {
            "label": "Button",
            "desc": [
                "Power x1",
                "Volume x2"
            ],
            "pic": "keyt.png"
        },
        {
            "label": "Adapter",
            "desc": [
                "Input: AC 100-240V ~ 50/60Hz 1A",
                "Output: DC 5V/3 A"
            ],
            "pic": "char.png"
        },
        {
            "label": "Dimension(LxWxH)",
            "desc": [
                "220.0mm x78.0mm x 16.8mm"
            ],
            "pic": "size.png"
        }
    ]
}
export const ks8226s = {
    "file": "ks8226s",
    "data": [
        {
            "label": "Processor",
            "desc": [
                "Quad-Core@1.1GHz)"
            ],
            "pic": "cpu.png"
        },
        {
            "label": "Memory",
            "desc": [
                "1GB RAM+8GB ROM",
                "2GB RAM+16GB ROM (optional) ",
                "MicroSDx1 support up to 32GB"
            ],
            "pic": "ram.png"
        },
        {
            "label": "OS",
            "desc": [
                "ZOS based on Android 7.1"
            ],
            "pic": "sys.png"
        },
        {
            "label": "Algorithm",
            "desc": [
                "3DES、AES、RSA、SHA-256、RSA、SM2、SM3、SM4、etc."
            ],
            "pic": "safe.png"
        },
        {
            "label": "Display",
            "desc": [
                "5” 720x1280",
                "Backlight control",
                "Multi-touch operation"
            ],
            "pic": "disp.png"
        },
        {
            "label": "NFC(optional)",
            "desc": [
                "13.56M  RFID"
            ],
            "pic": "nfc.png"
        },
        {
            "label": "Printer",
            "desc": [
                "Built-in 58mm thermal printer",
                "40mm paper roll"
            ],
            "pic": "prt.png"
        },
        {
            "label": "Camera",
            "desc": [
                "Rear: 5 mega pixels, flash, auto focus",
                "Front: 2 mega pixels",
                "Support 1D/2D"
            ],
            "pic": "face.png"
        },
        {
            "label": "Cellular Network",
            "desc": [
                "Dual sim slot (optional) ",
                " GSM / WCDMA / EVDO / TD-SCDMA /  TDD-LTE / FDD-LTE"
            ],
            "pic": "mobi.png"
        },
        {
            "label": "WIFI",
            "desc": [
                "IEEE 802.11 a / b / g / n",
                "2.4G Hz"
            ],
            "pic": "wifi.png"
        },
        {
            "label": "Bluetooth",
            "desc": [
                "Bluetooth 4.0 and above",
                "BLE"
            ],
            "pic": "blt.png"
        },
        {
            "label": "Location ",
            "desc": [
                "Built-in GPS/Beidou/Glonass",
                "LBS",
                "AGPS"
            ],
            "pic": "lbs.png"
        },
        {
            "label": "Biometric (optional)",
            "desc": [
                "Fingerprint",
                "Iris"
            ],
            "pic": "act.png"
        },
        {
            "label": "Speaker",
            "desc": [
                "Built-in speaker, 0.5 W",
                "82dB±3dB "
            ],
            "pic": "speaker.png"
        },
        {
            "label": "Port",
            "desc": [
                "USB Type-C x 1"
            ],
            "pic": "port.png"
        },
        {
            "label": "Keypad",
            "desc": [
                "Virtual pinpad"
            ],
            "pic": "keyb.png"
        },
        {
            "label": "Button",
            "desc": [
                "Power x1",
                "Volume x2"
            ],
            "pic": "keyt.png"
        },
        {
            "label": "Battery",
            "desc": [
                "LiPo battery, 3.7V/5200mAh",
                "Optional wide temperature battery"
            ],
            "pic": "battery.png"
        },
        {
            "label": "Adapter",
            "desc": [
                "Input: AC 100-240V",
                "Output: DC 5V/2A"
            ],
            "pic": "char.png"
        },
        {
            "label": "Dimension(L×W×H）",
            "desc": [
                "190mm×82mm×59mm"
            ],
            "pic": "size.png"
        },
        {
            "label": "Environment",
            "desc": [
                "Working temp: 0℃~+50℃",
                "Storage temp: -20℃~+70℃",
                "Relative humidity: 10%~90%RH(non-condensing)"
            ],
            "pic": "env.png"
        }
    ]
}
export const zt588f = {
    "file": "zt588f",
    "data": [
        {
            "label": "Port",
            "desc": [
                "RJ45×1",
                "USB-Bx1"
            ],
            "pic": "port.png"
        },
        {
            "label": "lnput voltage",
            "desc": [
                "DC5V±5%"
            ],
            "pic": "char.png"
        },
        {
            "label": "Number of keys",
            "desc": [
                "Number buttons × 10",
                "Function buttons × 6"
            ],
            "pic": "keyt.png"
        },
        {
            "label": "Optional",
            "desc": [
                "Extended function keyboard",
                "(scanning method 1×4)"
            ],
            "pic": "expand.png"
        },
        {
            "label": "Compliances",
            "desc": [
                "ISO,CE,RoHS"
            ],
            "pic": "stdok.png"
        },
        {
            "label": "Protection ability",
            "desc": [
                "Sensitive data can be kept for",
                "more than 5 years during power outage"
            ],
            "pic": "prtab.png"
        },
        {
            "label": "Key Layout",
            "desc": [
                "Ability to customized layouts"
            ],
            "pic": "kbchar.png"
        },
        {
            "label": "Protection level",
            "desc": [
                "Static:IP65",
                "Dynamic:lP54"
            ],
            "pic": "prtlv.png"
        },
        {
            "label": "EFT",
            "desc": [
                "Pass the anti-interference test with a repetition frequency of 100KHz and a voltage of 1.0KV"
            ],
            "pic": "eft.png"
        },
        {
            "label": "ESD",
            "desc": [
                "Withstand electrostatic attack of contact discharge ±6KV，air discharge ±8KV"
            ],
            "pic": "esd.png"
        },
        {
            "label": "Dimension(LxWxH)",
            "desc": [
                "146.5mm×115mm×34.3mm"
            ],
            "pic": "size.png"
        },
        {
            "label": "Environment",
            "desc": [
                "Working temp: -25℃~+60℃",
                "Storage temp: -40℃~+70℃",
                "Relative humidity: 10%~93%RH(non-condensing)"
            ],
            "pic": "env.png"
        }
    ]
}
export const zt598l = {
    "file": "zt598l",
    "data": [
        {
            "label": "Port",
            "desc": [
                "USB-B×1",
                "RJ45×1"
            ],
            "pic": "port.png"
        },
        {
            "label": "Input voltage",
            "desc": [
                "DC5V±5%"
            ],
            "pic": "char.png"
        },
        {
            "label": "Number of keys",
            "desc": [
                "Number buttons × 10",
                "Function buttons × 6"
            ],
            "pic": "keyt.png"
        },
        {
            "label": "Optional",
            "desc": [
                "Extended function keyboard",
                "(scanning method 1×4)"
            ],
            "pic": "expand.png"
        },
        {
            "label": "Compliances",
            "desc": [
                "ISO,CE,RoHS"
            ],
            "pic": "stdok.png"
        },
        {
            "label": "Protection ability",
            "desc": [
                "Sensitive data can be kept for",
                "more than 5 years during power outage"
            ],
            "pic": "prtab.png"
        },
        {
            "label": "Key Layout",
            "desc": [
                "Ability to customized layouts"
            ],
            "pic": "kbchar.png"
        },
        {
            "label": "Protection level",
            "desc": [
                "Static:IP65",
                "Dynamic:lP54"
            ],
            "pic": "prtlv.png"
        },
        {
            "label": "EFT",
            "desc": [
                "Pass the anti-interference test with a repetition frequency of 100KHz and a voltage of 1.0KV"
            ],
            "pic": "eft.png"
        },
        {
            "label": "ESD",
            "desc": [
                "Withstand electrostatic attack of contact discharge ±6KV，air discharge ±8KV"
            ],
            "pic": "esd.png"
        },
        {
            "label": "Button Pressure",
            "desc": [
                "2N~5.5N"
            ],
            "pic": "bpress.png"
        },
        {
            "label": "Key material",
            "desc": [
                "SUS304"
            ],
            "pic": "keymt.png"
        },
        {
            "label": "Dimension(LxWxH)",
            "desc": [
                "137mm ×88.3mm × 28.5mm"
            ],
            "pic": "size.png"
        },
        {
            "label": "Environment",
            "desc": [
                "Working temp: 0℃~+50℃",
                "Storage temp: -40℃~+70℃",
                "Relative humidity: 10%~93%RH(non-condensing)"
            ],
            "pic": "env.png"
        }
    ]
}
export const zt925 = {
    "file": "zt925",
    "data": [
        {
            "label": "Port",
            "desc": [
                "MiniUSB×1"
            ],
            "pic": "port.png"
        },
        {
            "label": "Input voltage",
            "desc": [
                "DC5V±5%"
            ],
            "pic": "char.png"
        },
        {
            "label": "Display dot matrix",
            "desc": [
                "132×48"
            ],
            "pic": "mtnum.png"
        },
        {
            "label": "Display character size",
            "desc": [
                "Support 16×8",
                "Chinese characters and 8×8"
            ],
            "pic": "scfont.png"
        },
        {
            "label": "Display",
            "desc": [
                "58mm × 27mm× 2.8mm"
            ],
            "pic": "scsize.png"
        },
        {
            "label": "Key Layout",
            "desc": [
                "Ability to customized layouts"
            ],
            "pic": "kbchar.png"
        },
        {
            "label": "Weight",
            "desc": [
                "0.21kg"
            ],
            "pic": "weight.png"
        },
        {
            "label": "Button Pressure",
            "desc": [
                "2N~4N"
            ],
            "pic": "bpress.png"
        },
        {
            "label": "key lifetime",
            "desc": [
                "Over ONE Million-Cycle",
                "Key Lifetime"
            ],
            "pic": "keylife.png"
        },
        {
            "label": "Number of keys",
            "desc": [
                "Number buttons × 10",
                "Function buttons × 6"
            ],
            "pic": "keyt.png"
        },
        {
            "label": "Compliances",
            "desc": [
                "ISO,CE,RoHS"
            ],
            "pic": "stdok.png"
        },
        {
            "label": "Protection ability",
            "desc": [
                "Sensitive data can be kept for",
                "more than 5 years during power outage"
            ],
            "pic": "prtab.png"
        },
        {
            "label": "EFT",
            "desc": [
                "Pass the anti-interference test with a repetition frequency of 100KHz and a voltage of 1.0KV"
            ],
            "pic": "eft.png"
        },
        {
            "label": "ESD",
            "desc": [
                "Withstand electrostatic attack of contact discharge ±6KV，air discharge ±8KV"
            ],
            "pic": "esd.png"
        },
        {
            "label": "Dimension",
            "desc": [
                "136mm×76m×67mm"
            ],
            "pic": "size.png"
        },
        {
            "label": "Environment",
            "desc": [
                "Working temp: -25℃~+50℃",
                "Storage temp: -25℃~+50℃",
                "Relative humidity: 10%~90%RH(non-condensing)"
            ],
            "pic": "env.png"
        }
    ]
}
export const zt598m = {
    "file": "zt598m",
    "data": [
        {
            "label": "Port",
            "desc": [
                "USB-B×1",
                "RJ45×1"
            ],
            "pic": "port.png"
        },
        {
            "label": "lnput voltage",
            "desc": [
                "DC5V±5%"
            ],
            "pic": "char.png"
        },
        {
            "label": "Number of keys",
            "desc": [
                "Number buttons × 10",
                "Function buttons × 6"
            ],
            "pic": "keyt.png"
        },
        {
            "label": "Optional",
            "desc": [
                "Extended function keyboard",
                "(scanning method 1×4)"
            ],
            "pic": "expand.png"
        },
        {
            "label": "Function support",
            "desc": [
                "PIN encryption, MAC calculation,",
                "data encryption/decryption, remote key",
                "management, remote firmware update"
            ],
            "pic": "funsp.png"
        },
        {
            "label": "Compliances",
            "desc": [
                "ISO,CE,RoHS"
            ],
            "pic": "stdok.png"
        },
        {
            "label": "Protection ability",
            "desc": [
                "Sensitive data can be kept for more than 7 years during power outage"
            ],
            "pic": "prtab.png"
        },
        {
            "label": "Button pressure",
            "desc": [
                "2N~5.5N"
            ],
            "pic": "bpress.png"
        },
        {
            "label": "Vandal resistance level",
            "desc": [
                "IK08"
            ],
            "pic": "prtlv.png"
        },
        {
            "label": "Protection level",
            "desc": [
                "Static:lP65",
                "Dynamic:IP54"
            ],
            "pic": "prtw.png"
        },
        {
            "label": "Key material",
            "desc": [
                "SUS304"
            ],
            "pic": "keymt.png"
        },
        {
            "label": "EFT",
            "desc": [
                "Pass the anti-interference test with a repetition frequency of 100KHz and a voltage of 1.0KV"
            ],
            "pic": "eft.png"
        },
        {
            "label": "ESD",
            "desc": [
                "Withstand electrostatic attack of contact discharge ±6KV，air discharge ±8KV"
            ],
            "pic": "esd.png"
        },
        {
            "label": "Environment",
            "desc": [
                "Working temp: O℃-+50℃",
                "Storage temp: -40℃~+70℃",
                "Relative humidity: 10%~93%RH(non-condensing)"
            ],
            "pic": "env.png"
        }
    ]
}
export const zt598h = {
    "file": "zt598h",
    "data": [
        {
            "label": "Port",
            "desc": [
                "USB-Bx1(default)",
                "USB-Bx2(Optional)"
            ],
            "pic": "port.png"
        },
        {
            "label": "Input voltage",
            "desc": [
                "DC5V±5%"
            ],
            "pic": "char.png"
        },
        {
            "label": "Number of keys",
            "desc": [
                "Number buttons × 10",
                "Function buttons × 6"
            ],
            "pic": "keyt.png"
        },
        {
            "label": "Optional",
            "desc": [
                "Extended function keyboard",
                "(scanning method 1×4)"
            ],
            "pic": "expand.png"
        },
        {
            "label": "Function support",
            "desc": [
                "PIN encryption, MAC calculation,",
                "data encryption/decryption, remote key",
                "management, remote firmware update"
            ],
            "pic": "funsp.png"
        },
        {
            "label": "Compliances",
            "desc": [
                "ISO,CE,RoHS"
            ],
            "pic": "stdok.png"
        },
        {
            "label": "Protection ability",
            "desc": [
                "Sensitive data can be kept for more than 7 years during power outage"
            ],
            "pic": "prtab.png"
        },
        {
            "label": "Button pressure",
            "desc": [
                "2N~5.5N"
            ],
            "pic": "bpress.png"
        },
        {
            "label": "Vandal resistance level",
            "desc": [
                "IK08"
            ],
            "pic": "prtlv.png"
        },
        {
            "label": "Protection level",
            "desc": [
                "Static:IP65",
                "Dynamic:IP54"
            ],
            "pic": "prtw.png"
        },
        {
            "label": "Key material",
            "desc": [
                "SUS304"
            ],
            "pic": "keymt.png"
        },
        {
            "label": "EFT",
            "desc": [
                "Pass the anti-interference test with a repetition frequency of 100KHz and a voltage of 1.0KV"
            ],
            "pic": "eft.png"
        },
        {
            "label": "ESD",
            "desc": [
                "Withstand electrostatic attack of contact discharge ±6KV，air discharge ±8KV"
            ],
            "pic": "esd.png"
        },
        {
            "label": "Environment",
            "desc": [
                "Working temp: O℃-+50℃",
                "Storage temp: -40℃~+70℃",
                "Relative humidity: 10%~93%RH(non-condensing)"
            ],
            "pic": "env.png"
        }
    ]
}
export const zt598b = {
    "file": "zt598b",
    "data": [
        {
            "label": "Port",
            "desc": [
                "USB-B×1",
                "RJ45×1"
            ],
            "pic": "port.png"
        },
        {
            "label": "Input voltage",
            "desc": [
                "DC5V±5%"
            ],
            "pic": "char.png"
        },
        {
            "label": "Number of keys",
            "desc": [
                "Number buttons × 10",
                "Function buttons × 6"
            ],
            "pic": "keyt.png"
        },
        {
            "label": "Optional",
            "desc": [
                "Extended function keyboard",
                "(scanning method 1×4)"
            ],
            "pic": "expand.png"
        },
        {
            "label": "Compliances",
            "desc": [
                "ISO,CE,RoHS"
            ],
            "pic": "stdok.png"
        },
        {
            "label": "Protection ability",
            "desc": [
                "Sensitive data can be kept for",
                "more than 5 years during power outage"
            ],
            "pic": "prtab.png"
        },
        {
            "label": "Key Layout",
            "desc": [
                "Ability to customized layouts"
            ],
            "pic": "kbchar.png"
        },
        {
            "label": "Protection level",
            "desc": [
                "Static:IP65",
                "Dynamic:lP54"
            ],
            "pic": "prtlv.png"
        },
        {
            "label": "EFT",
            "desc": [
                "Pass the anti-interference test with a repetition frequency of 100KHz and a voltage of 1.0KV"
            ],
            "pic": "eft.png"
        },
        {
            "label": "ESD",
            "desc": [
                "Withstand electrostatic attack of contact discharge ±6KV，air discharge ±8KV"
            ],
            "pic": "esd.png"
        },
        {
            "label": "Button Pressure",
            "desc": [
                "2N~5.5N"
            ],
            "pic": "bpress.png"
        },
        {
            "label": "Key material",
            "desc": [
                "SUS304"
            ],
            "pic": "keymt.png"
        },
        {
            "label": "Dimension(LxWxH)",
            "desc": [
                "137mm ×88.3mm × 28.5mm"
            ],
            "pic": "size.png"
        },
        {
            "label": "Environment",
            "desc": [
                "Working temp: 0℃~+50℃",
                "Storage temp: -40℃~+70℃",
                "Relative humidity: 10%~93%RH(non-condensing)"
            ],
            "pic": "env.png"
        }
    ]
}
export const zt599nm = {
    "file": "zt599nm",
    "data": [
        {
            "label": "Port",
            "desc": [
                "USB2.0×1"
            ],
            "pic": "port.png"
        },
        {
            "label": "Input voltage",
            "desc": [
                "DC5V±5%"
            ],
            "pic": "char.png"
        },
        {
            "label": "Compliances",
            "desc": [
                "SO/IEC 9995 and GB/T 14081"
            ],
            "pic": "stdok.png"
        },
        {
            "label": "Protection ability",
            "desc": [
                "Sensitive data can be kept for more than 7 years during power outage"
            ],
            "pic": "prtab.png"
        },
        {
            "label": "Button Pressure",
            "desc": [
                "1N~3N"
            ],
            "pic": "bpress.png"
        },
        {
            "label": "Dimension(LxWxH)",
            "desc": [
                "358mm × 128mm × 46.1mm"
            ],
            "pic": "size.png"
        },
        {
            "label": "Panel dimension",
            "desc": [
                "330mm ×100mm"
            ],
            "pic": "psize.png"
        },
        {
            "label": "EFT",
            "desc": [
                "Pass the anti-interference test with a repetition frequency of 100KHz and a voltage of 1.0KV"
            ],
            "pic": "eft.png"
        },
        {
            "label": "ESD",
            "desc": [
                "Withstand electrostatic attack of contact discharge ±6KV，air discharge ±8KV"
            ],
            "pic": "esd.png"
        },
        {
            "label": "Environment",
            "desc": [
                "Working temp: -25℃-+50℃",
                "Storage temp: -25℃~+55℃",
                "Relative humidity: 10%~93%RH(non-condensing)"
            ],
            "pic": "env.png"
        }
    ]
}
export const zt599n = {
    "file": "zt599n",
    "data": [
        {
            "label": "Port",
            "desc": [
                "USB2.0×1",
                "PS/2port（optional）"
            ],
            "pic": "port.png"
        },
        {
            "label": "lnput voltage",
            "desc": [
                "DC5V±5%"
            ],
            "pic": "char.png"
        },
        {
            "label": "Compliances",
            "desc": [
                "ISO,CE,RoHS"
            ],
            "pic": "stdok.png"
        },
        {
            "label": "Protection ability",
            "desc": [
                "Sensitive data can be kept for more than 7 years during power outage"
            ],
            "pic": "prtab.png"
        },
        {
            "label": "Button pressure",
            "desc": [
                "1N~3N"
            ],
            "pic": "bpress.png"
        },
        {
            "label": "Dimension ",
            "desc": [
                "358mm × 128mm × 46.1mm"
            ],
            "pic": "size.png"
        },
        {
            "label": "Panel dimension ",
            "desc": [
                "330mm ×100mm"
            ],
            "pic": "psize.png"
        },
        {
            "label": "EFT",
            "desc": [
                "Pass the anti-interference test with a repetition frequency of 100KHz and a voltage of 1.0KV"
            ],
            "pic": "eft.png"
        },
        {
            "label": "ESD",
            "desc": [
                "Withstand electrostatic attack of contact discharge ±6KV，air discharge ±8KV"
            ],
            "pic": "esd.png"
        },
        {
            "label": "Environment",
            "desc": [
                "Working temp: -25℃-+50℃",
                "Storage temp: -25℃~+55℃",
                "Relative humidity: 10%~93%RH(non-condensing)"
            ],
            "pic": "env.png"
        }
    ]
}
export const zt8211 = {
    "file": "zt8211",
    "data": [
        {
            "label": "CPU",
            "desc": [
                "Quad-core 2.0GHz CPU"
            ],
            "pic": "cpu.png"
        },
        {
            "label": "Memory",
            "desc": [
                "2GB RAM + 16GB ROM "
            ],
            "pic": "ram.png"
        },
        {
            "label": "OS",
            "desc": [
                "Android 9.0"
            ],
            "pic": "sys.png"
        },
        {
            "label": "Screen",
            "desc": [
                "3.5”FWVGA, 854x480 Pixel"
            ],
            "pic": "disp.png"
        },
        {
            "label": "Camera",
            "desc": [
                "8 Megapixel"
            ],
            "pic": "face.png"
        },
        {
            "label": "Touch",
            "desc": [
                "Multi-point PCAP",
                "(Projective Capacitive Touch)"
            ],
            "pic": "touch.png"
        },
        {
            "label": "Wireless",
            "desc": [
                "GSM: 900/1800 MHz",
                "WCDMA: 2100 MHz"
            ],
            "pic": "mobi.png"
        },
        {
            "label": "WI-FI",
            "desc": [
                "802.11 b/g/n/ac"
            ],
            "pic": "wifi.png"
        },
        {
            "label": "Bluetooth",
            "desc": [
                "BT5.0,v3.0+HS/V4.2 LE"
            ],
            "pic": "blt.png"
        },
        {
            "label": "Location",
            "desc": [
                "GPS/APS/GCONASS/GALILEO"
            ],
            "pic": "lbs.png"
        },
        {
            "label": "Laser Safety",
            "desc": [
                "IEC 62471/IEC60825"
            ],
            "pic": "safe.png"
        },
        {
            "label": "Accessories",
            "desc": [
                "Adapter, Cable, handstrap, Charging Cradle, 4-slot battery charger."
            ],
            "pic": "part.png"
        },
        {
            "label": "Scanner",
            "desc": [
                "iQR，PDF417，DataMatrix，MaxiCode etc. Code 39，Codabar， Code 128，Discrete 2 of 5，UPC，EAN，MSI etc."
            ],
            "pic": "scan.png"
        },
        {
            "label": "Port",
            "desc": [
                "Type-C USB"
            ],
            "pic": "port.png"
        },
        {
            "label": "Keypad",
            "desc": [
                "Number keysx10",
                "Function keysx14"
            ],
            "pic": "keyb.png"
        },
        {
            "label": "Battery ",
            "desc": [
                "Expected Battery Life Talking 20 Hours",
                "Expected Battery Life Standby 500 Hours",
                "4.2V/4000mAh"
            ],
            "pic": "battery.png"
        },
        {
            "label": "Dimensions",
            "desc": [
                "169(L)x72(W)x23.8(H) mm"
            ],
            "pic": "size.png"
        },
        {
            "label": "Environment",
            "desc": [
                "Operating Temperature：-20℃~+55℃",
                "Storage Temperature：-30℃~+70℃",
                "Humidity: 5%~95%",
                "Sealing : IP64"
            ],
            "pic": "env.png"
        }
    ]
}
export const zt8212 = {
    "file": "zt8212",
    "data": [
        {
            "label": "CPU",
            "desc": [
                "Quad-core 2.0GHz"
            ],
            "pic": "cpu.png"
        },
        {
            "label": "Memory",
            "desc": [
                "2GB RAM + 16GB ROM ",
                "MicroSDx1 support up to 128GB"
            ],
            "pic": "ram.png"
        },
        {
            "label": "OS",
            "desc": [
                "Android 9.0"
            ],
            "pic": "sys.png"
        },
        {
            "label": "Screen",
            "desc": [
                "4” WVGA(800x480) Pixel",
                "Capacitive touch screen"
            ],
            "pic": "disp.png"
        },
        {
            "label": "Audio",
            "desc": [
                "Microphone/speaker"
            ],
            "pic": "speaker.png"
        },
        {
            "label": "Wireless",
            "desc": [
                "2G/3G/4G"
            ],
            "pic": "mobi.png"
        },
        {
            "label": "WI-FI",
            "desc": [
                "802.11 a/b/n/ac",
                "2.4GHz and 5GHz"
            ],
            "pic": "wifi.png"
        },
        {
            "label": "Bluetooth",
            "desc": [
                "BT5.0,v3.0+HS/V4.2 LE"
            ],
            "pic": "blt.png"
        },
        {
            "label": "Location",
            "desc": [
                "GPS/APS/GCONASS/GALILEO"
            ],
            "pic": "lbs.png"
        },
        {
            "label": "Laser Safety",
            "desc": [
                "IEC2/FDAII, IEC60825-/EN60825-1"
            ],
            "pic": "safe.png"
        },
        {
            "label": "Scanner",
            "desc": [
                "1D",
                "2D"
            ],
            "pic": "scan.png"
        },
        {
            "label": "Port",
            "desc": [
                "Micro USB 2.0,charging interface,",
                "Micro USB 2.0,charging interface,"
            ],
            "pic": "port.png"
        },
        {
            "label": "Keypad",
            "desc": [
                "28 keys (side keys included)"
            ],
            "pic": "keyb.png"
        },
        {
            "label": "NFC",
            "desc": [
                "ISO14443A/B ISO15693,ISO18000-3,",
                "Mifare,FeliCa RF, kovio, NFC Forum,",
                "Type1-4 Tag"
            ],
            "pic": "nfc.png"
        },
        {
            "label": "Battery ",
            "desc": [
                "4.2V/4000 mAH",
                "Standby: 500 Hours",
                "Scanning:11 Hours"
            ],
            "pic": "battery.png"
        },
        {
            "label": "Weight",
            "desc": [
                "330g(including battery)"
            ],
            "pic": "weight.png"
        },
        {
            "label": "Dimensions",
            "desc": [
                "172(L)x72(W)x24.8(H) mm"
            ],
            "pic": "size.png"
        },
        {
            "label": "Environment",
            "desc": [
                "Operating Temperature：-20℃~+55℃",
                "Storage Temperature：-30℃~+70℃",
                "Humidity: 5%~95%",
                "Sealing : IP65"
            ],
            "pic": "env.png"
        }
    ]
}
export const zt8213 = {
    "file": "zt8213",
    "data": [
        {
            "label": "CPU",
            "desc": [
                "Quad-core 64-bit processor, 1.5GHz "
            ],
            "pic": "cpu.png"
        },
        {
            "label": "Memory",
            "desc": [
                "2GB RAM + 16GB ROM ",
                "Memory Card (up to 128 GB) "
            ],
            "pic": "ram.png"
        },
        {
            "label": "OS",
            "desc": [
                "Android 7.0 / 8.1"
            ],
            "pic": "sys.png"
        },
        {
            "label": "Display",
            "desc": [
                "5.7” (resolution 1440x720)",
                "Notification",
                "Vibrator, speaker, three-color LED"
            ],
            "pic": "disp.png"
        },
        {
            "label": "Camera",
            "desc": [
                "Rear camera 13 MP, Auto-focus,",
                "support flash",
                "Front camera 2MP,fix focus"
            ],
            "pic": "face.png"
        },
        {
            "label": "Wireless",
            "desc": [
                "2G/3G/4G"
            ],
            "pic": "mobi.png"
        },
        {
            "label": "WI-FI",
            "desc": [
                "802.11 a/b/n/ac",
                "2.4GHz and 5GHz"
            ],
            "pic": "wifi.png"
        },
        {
            "label": "Bluetooth",
            "desc": [
                "Bluetooth 4.2"
            ],
            "pic": "blt.png"
        },
        {
            "label": "Location",
            "desc": [
                "GPS/Beidou/GCONASS"
            ],
            "pic": "lbs.png"
        },
        {
            "label": "Fingerprint ",
            "desc": [
                "Support(standard)"
            ],
            "pic": "fgip.png"
        },
        {
            "label": "Scanner",
            "desc": [
                "1D",
                "2D"
            ],
            "pic": "scan.png"
        },
        {
            "label": "Sensor",
            "desc": [
                "Distance sensor, light sensor, ",
                "Geomagnetism sensor, Acceleration sensor"
            ],
            "pic": "sensor.png"
        },
        {
            "label": "Port",
            "desc": [
                "Type C"
            ],
            "pic": "port.png"
        },
        {
            "label": "Battery ",
            "desc": [
                "Output: DC 5V/9V 1.67A ",
                "Input: AC 100~240V",
                "4.35V, 4800mAh"
            ],
            "pic": "battery.png"
        },
        {
            "label": "Accessories",
            "desc": [
                "AC Adapter, Cable, Charging Cradle, ",
                "4-slot charging cradle. "
            ],
            "pic": "part.png"
        },
        {
            "label": "Dimensions",
            "desc": [
                "157(L)x76.3(W)x12.6(H) mm"
            ],
            "pic": "size.png"
        },
        {
            "label": "Environment",
            "desc": [
                "Operating Temperature：-20℃~+55℃",
                "Storage Temperature：-30℃~+70℃",
                "Humidity: 5%~95%",
                "Sealing : IP67"
            ],
            "pic": "env.png"
        }
    ]
}
export const zt8669 = {
    "file": "zt8669",
    "data": [
        {
            "label": "Processor",
            "desc": [
                "Quad core Cortex-A73@2.0 Ghz",
                "Quad core Cortex-A53@2.0 Ghz"
            ],
            "pic": "cpu.png"
        },
        {
            "label": "Memory",
            "desc": [
                "4GB RAM +64GB ROM",
                "8GB RAM+256GB ROM(optional)",
                "MicroSDx1"
            ],
            "pic": "ram.png"
        },
        {
            "label": "OS",
            "desc": [
                "Based on Android 9.0"
            ],
            "pic": "sys.png"
        },
        {
            "label": "Display",
            "desc": [
                "11.6”1920×1080",
                "Multi-touch capacitive touch screen"
            ],
            "pic": "disp.png"
        },
        {
            "label": "Camera",
            "desc": [
                "130 Mega pixels",
                "1280×720",
                "130 Mega pixels"
            ],
            "pic": "face.png"
        },
        {
            "label": "Facial",
            "desc": [
                "Automatic infrared fill light, autofocus Live detection",
                "Optional 3D structured light module"
            ],
            "pic": "face1.png"
        },
        {
            "label": "Cellular Network",
            "desc": [
                "GSM / WCDMA / EVDO / TDD-LTE / FDD-LTE"
            ],
            "pic": "mobi.png"
        },
        {
            "label": "WIFI",
            "desc": [
                "2.4GHz +5GHz"
            ],
            "pic": "wifi.png"
        },
        {
            "label": "Ethernet",
            "desc": [
                "10M / 100M / 1000M bps"
            ],
            "pic": "ethr1.png"
        },
        {
            "label": "Bluetooth",
            "desc": [
                "Bluetooth 4.0 and above",
                "BLE"
            ],
            "pic": "blt.png"
        },
        {
            "label": "Location",
            "desc": [
                "Built-in GPS/Beidou/Glonass",
                "LBS",
                "AGPS"
            ],
            "pic": "lbs.png"
        },
        {
            "label": "Card Reader",
            "desc": [
                "Magnetic ×1、Contact ×1",
                "Contactless ×1、PSAMX1"
            ],
            "pic": "read.png"
        },
        {
            "label": "Printer",
            "desc": [
                "Built-in 58 mm thermal printer",
                "Paper roll 40mm"
            ],
            "pic": "prt.png"
        },
        {
            "label": "Scanner",
            "desc": [
                "QR/ EAN-13/ UPC-A/ UPC-E/EAN-8 / Code 128/ Code39 / lnterleaved 2of5"
            ],
            "pic": "scan.png"
        },
        {
            "label": "ID",
            "desc": [
                "Frequency:13.56Mhz±7Khz"
            ],
            "pic": "2id.png"
        },
        {
            "label": "Keypad",
            "desc": [
                "Power buttonx1",
                "Encrypted pinpad"
            ],
            "pic": "keyb.png"
        },
        {
            "label": "Speaker",
            "desc": [
                "Built-in speaker,2W"
            ],
            "pic": "speaker.png"
        },
        {
            "label": "Port",
            "desc": [
                "USBTypeAx2/ RS232×x1/ RJ45×1"
            ],
            "pic": "port.png"
        },
        {
            "label": "Adapter",
            "desc": [
                "lnput: AC100-240V",
                "Output: DC12V /3A"
            ],
            "pic": "char.png"
        },
        {
            "label": "Dimension(LxW×H)",
            "desc": [
                "285mm×385mm×65mm"
            ],
            "pic": "size.png"
        }
    ]
}
export const zt8350p = {
    "file": "zt8350p",
    "data": [
        {
            "label": "Processor",
            "desc": [
                "Quad core@1.1GHz"
            ],
            "pic": "cpu.png"
        },
        {
            "label": "Memory",
            "desc": [
                "1GB RAM+8GB ROM",
                "2GB RAM+16GB ROM（Optional）",
                "MicroSD x1 support up to 32 GB"
            ],
            "pic": "ram.png"
        },
        {
            "label": "OS",
            "desc": [
                "ZOS based on Android 5.1/7.1"
            ],
            "pic": "sys.png"
        },
        {
            "label": "Display",
            "desc": [
                "Primary: 5.5\"720 x 1280 TFT",
                "Back Indicator control,multi-touch",
                "Secondary display; 132x32 dot matrix"
            ],
            "pic": "disp.png"
        },
        {
            "label": "Fiscal Module",
            "desc": [
                "128M encrypted storage",
                "3DES,AES, RSA,SHA-256,etc."
            ],
            "pic": "tax1.png"
        },
        {
            "label": "Cellular Network",
            "desc": [
                "GSM / WCDMA / EVDO / TD-SCDMA /  TDD-LTE / FDD-LTE"
            ],
            "pic": "mobi.png"
        },
        {
            "label": "WIFI",
            "desc": [
                "IEEE 802.11 b /g/ n",
                "2.4GHz"
            ],
            "pic": "wifi.png"
        },
        {
            "label": "Bluetooth",
            "desc": [
                "Bluetooth 4.0 and above",
                "BLE"
            ],
            "pic": "blt.png"
        },
        {
            "label": "Location",
            "desc": [
                "Built-in GPS/Beidou/Glonass",
                "LBS",
                "AGPS"
            ],
            "pic": "lbs.png"
        },
        {
            "label": "Printer",
            "desc": [
                "58mm thermal printer",
                "40mm roll paper"
            ],
            "pic": "prt.png"
        },
        {
            "label": "Speaker",
            "desc": [
                "Built-in speaker, 0.5W",
                "82dB±3dB"
            ],
            "pic": "speaker.png"
        },
        {
            "label": "Port",
            "desc": [
                "USB ×1、Cash drawer x1",
                "RJ45 Ethernet x1、RS232x1",
                "Power x1"
            ],
            "pic": "port.png"
        },
        {
            "label": "Keypad",
            "desc": [
                "Physical keys x30",
                "Power x1"
            ],
            "pic": "keyt.png"
        },
        {
            "label": "Indicator",
            "desc": [
                "Charging ×1、power x 1",
                "Network x 1、user-defined x1"
            ],
            "pic": "lamp.png"
        },
        {
            "label": "Battery",
            "desc": [
                "Lithium battery 7.4V/2550mAH"
            ],
            "pic": "battery.png"
        },
        {
            "label": "Adapter",
            "desc": [
                "Input: AC100-240V",
                "Output: DC 9v/4A"
            ],
            "pic": "char.png"
        },
        {
            "label": "Dimension(LxWxH)",
            "desc": [
                "230mm×105mm×45mm"
            ],
            "pic": "size.png"
        },
        {
            "label": "Environment",
            "desc": [
                "Working temp: 0℃~+50℃",
                "Storage temp: -20°℃~+70°℃",
                "Relative humidity : 10%~90%RH(non-condensing)"
            ],
            "pic": "env.png"
        }
    ]
}
export const zt8350 = {
    "file": "zt8350",
    "data": [
        {
            "label": "Processor",
            "desc": [
                "Quad core@1.1GHz"
            ],
            "pic": "cpu.png"
        },
        {
            "label": "Memory",
            "desc": [
                "1GB RAM+8GB ROM",
                "2GB RAM+16GB ROM（Optional）",
                "MicroSD x1 support up to 32 GB"
            ],
            "pic": "ram.png"
        },
        {
            "label": "OS",
            "desc": [
                "ZOS based on Android 5.1/7.1"
            ],
            "pic": "sys.png"
        },
        {
            "label": "Display",
            "desc": [
                "Primary: 3.5\"320 x 240 TFT",
                "Secondary: 132x32 dot matrix"
            ],
            "pic": "disp.png"
        },
        {
            "label": "Fiscal Module",
            "desc": [
                "128M encrypted storage",
                "3DES,AES, RSA,SHA-256,etc."
            ],
            "pic": "tax1.png"
        },
        {
            "label": "Cellular Network",
            "desc": [
                "GSM / WCDMA / EVDO / TD-SCDMA /  TDD-LTE / FDD-LTE"
            ],
            "pic": "mobi.png"
        },
        {
            "label": "WIFI",
            "desc": [
                "IEEE 802.11 b /g/ n",
                "2.4GHz"
            ],
            "pic": "wifi.png"
        },
        {
            "label": "Bluetooth",
            "desc": [
                "Bluetooth 4.0 and above",
                "BLE"
            ],
            "pic": "blt.png"
        },
        {
            "label": "Location",
            "desc": [
                "Built-in GPS/Beidou/Glonass",
                "LBS",
                "AGPS"
            ],
            "pic": "lbs.png"
        },
        {
            "label": "Printer",
            "desc": [
                "58mm thermal printer",
                "40mm roll paper"
            ],
            "pic": "prt.png"
        },
        {
            "label": "Speaker",
            "desc": [
                "Built-in speaker, 0.5W",
                "82dB±3dB"
            ],
            "pic": "speaker.png"
        },
        {
            "label": "Port",
            "desc": [
                "USB ×1、Cash drawer x1",
                "RJ45 Ethernet x1、RS232x1",
                "Power x1"
            ],
            "pic": "port.png"
        },
        {
            "label": "Keypad",
            "desc": [
                "Physical keys x30",
                "Power x1",
                "Volume keysx2"
            ],
            "pic": "keyt.png"
        },
        {
            "label": "Indicator",
            "desc": [
                "Charging ×1、power x 1",
                "Network x 1、user-defined x1"
            ],
            "pic": "lamp.png"
        },
        {
            "label": "Battery",
            "desc": [
                "Lithium battery 7.4V/2550mAH"
            ],
            "pic": "battery.png"
        },
        {
            "label": "Adapter",
            "desc": [
                "Input: AC100-240V",
                "Output: DC 9v/4A"
            ],
            "pic": "char.png"
        },
        {
            "label": "Dimension(LxWxH)",
            "desc": [
                "230mm×105mm×45mm"
            ],
            "pic": "size.png"
        },
        {
            "label": "Environment",
            "desc": [
                "Working temp: 0℃~+50℃",
                "Storage temp: -20°℃~+70°℃",
                "Relative humidity : 10%~90%RH(non-condensing)"
            ],
            "pic": "env.png"
        }
    ]
}
export const zt8351 = {
    "file": "zt8351",
    "data": [
        {
            "label": "Processor",
            "desc": [
                "Quad core@1.1GHz"
            ],
            "pic": "cpu.png"
        },
        {
            "label": "Memory",
            "desc": [
                "1GB RAM+8GB ROM",
                "2GB RAM+16GB ROM（Optional）",
                "MicroSD x1 support up to 32 GB"
            ],
            "pic": "ram.png"
        },
        {
            "label": "OS",
            "desc": [
                "ZOS based on Android 5.1/7.1"
            ],
            "pic": "sys.png"
        },
        {
            "label": "Display",
            "desc": [
                "132 x32 dot matrix"
            ],
            "pic": "disp.png"
        },
        {
            "label": "Fiscal Module",
            "desc": [
                "128M encrypted storage",
                "3DES,AES, RSA,SHA-256,etc."
            ],
            "pic": "tax1.png"
        },
        {
            "label": "Cellular Network",
            "desc": [
                "GSM / WCDMA / EVDO / TD-SCDMA /  TDD-LTE / FDD-LTE"
            ],
            "pic": "mobi.png"
        },
        {
            "label": "WIFI",
            "desc": [
                "IEEE 802.11 b /g/ n",
                "2.4GHz"
            ],
            "pic": "wifi.png"
        },
        {
            "label": "Bluetooth",
            "desc": [
                "Bluetooth 4.0 and above",
                "BLE"
            ],
            "pic": "blt.png"
        },
        {
            "label": "Location",
            "desc": [
                "Built-in GPS/Beidou/Glonass",
                "LBS",
                "AGPS"
            ],
            "pic": "lbs.png"
        },
        {
            "label": "Printer",
            "desc": [
                "58mm thermal printer",
                "40mm roll paper"
            ],
            "pic": "prt.png"
        },
        {
            "label": "Speaker",
            "desc": [
                "Built-in speaker, 0.5W",
                "82dB±3dB"
            ],
            "pic": "speaker.png"
        },
        {
            "label": "Port",
            "desc": [
                "USB ×1、Micro USB x1",
                "RJ45 Ethernet x1、PSAMx1"
            ],
            "pic": "port.png"
        },
        {
            "label": "Keypad",
            "desc": [
                "Power x1"
            ],
            "pic": "keyt.png"
        },
        {
            "label": "Indicator",
            "desc": [
                "Charging ×1、power x 1",
                "Network x 1、user-defined x1"
            ],
            "pic": "lamp.png"
        },
        {
            "label": "Battery",
            "desc": [
                "Lithium battery 3.7V/2550mAH"
            ],
            "pic": "battery.png"
        },
        {
            "label": "Adapter",
            "desc": [
                "Input: AC110-240V",
                "Output: DC 5v/4A"
            ],
            "pic": "char.png"
        },
        {
            "label": "Dimension(LxWxH)",
            "desc": [
                "130mm x 100mm x28.9mm"
            ],
            "pic": "size.png"
        },
        {
            "label": "Environment",
            "desc": [
                "Working temp: 0℃~+50℃",
                "Storage temp: -20°℃~+70°℃",
                "Relative humidity : 10%~90%RH(non-condensing)"
            ],
            "pic": "env.png"
        }
    ]
}
